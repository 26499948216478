import React, { useRef, useEffect, useState } from 'react'
import Container from './Container'
import {isMobile} from 'react-device-detect'
import clickdrag from 'react-clickdrag'

var THREE = require('three')
var OBJLoader = require('three-obj-loader')
var OrbitControls = require('three-orbitcontrols')
OBJLoader(THREE)
//console.log(typeof THREE.OBJLoader)

const textureFolder = 'js/three/board_images/'

const brands = {
  'Pretty Puke': {
    logo: '/images/brandlogos/prettypukelogo.png',
    url: 'prettypuke.com'
  },
  'Thrasher Boyz': {
    logo: '/images/brandlogos/thrasherboyzlogo.png',
    url: 'thrashermagazine.com'
  }
}

const textures = [
  //{image : 'toymachine1.jpg', thumb: 'toymachine1_thumb.jpg', longThumb: 'toymachine1_longthumb.jpg'},
  {image : 'toymachine2.jpg', thumb: 'toymachine2_thumb.jpg', longThumb: 'toymachine2_longthumb.jpg'},
  {image : 'alien1.jpg', thumb: 'alien1_thumb.jpg', longThumb: 'alien1_longthumb.jpg'},
  {image : 'ripndip1.jpg', thumb: 'ripndip1_thumb.jpg', longThumb: 'ripndip1_longthumb.jpg'},
  //{image : 'planb1.jpg', thumb: 'planb1_thumb.jpg', longThumb: 'planb1_longthumb.jpg'},
  {image : 'prettypuke1.jpg', thumb: 'prettypuke1_thumb.jpg', longThumb: 'prettypuke1_longthumb.jpg', brandName: 'Pretty Puke'},
  {image : 'thrasherboys1.jpg', thumb: 'thrasherboys1_thumb.jpg', longThumb: 'thrasherboys1_longthumb.jpg', brandName: 'Thrasher Boyz'},
  {image : 'baker1.jpg', thumb: 'baker1_thumb.jpg', longThumb: 'baker1_longthumb.jpg'},
  {image : 'blocks1.jpg', thumb: 'blocks1_thumb.jpg', longThumb: 'blocks1_longthumb.jpg'},
]


const BrandLogo = (props) => {
  return(
    <Container
      onClick={() => window.open("https://" + props.brand.url, '_blank')}
    >
      <img src={props.brand.logo} draggable='false' alt='brand logo' style={{width: '100%', objectFit: 'contain'}} />
    </Container>
  )
}

const TextureThumbnail = (props) => {
  return (
    <Container
    onMouseEnter={() => {
      props.selectHoveredTexture(props.index)
    }}
    onMouseLeave={() => {
      props.selectHoveredTexture(null)
    }}
    onClick={() => {
      props.selectTexture(props.index)
    }}
    style= {{
      position: 'relative',
      height: props.spacing,
      width: props.sideLength*3,
    }}    
  >
    <Container
      style={{
        height: props.sideLength,
        top: '0px'
      }}
    >
        <img alt={props.texture.image} style={{width: '100%', objectFit: 'cover'}} src={textureFolder + props.texture.longThumb} />
    </Container>

  </Container>
  )
}

const MobileTexturePicker = props => {
  return(
    <Container
      style={{
        width: `${props.width}px`,
        height: `${props.sideLength}px`,
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
        bottom : '10px',
      }}
    >
      <Container 
        style={{
          justifyContent: 'center', 
          height: `${props.sideLength}px`, 
          right: `${-1*props.sliderPos}px`,
          width: `${textures.length*props.sideLength}px`,
          minWidth: `${textures.length*props.sideLength}px`,
        }}>
        { 
          textures.map((texture, index) =>
              <Container
                key={`texture_select_${index}`}
                onClick={() => {
                  if(textures[index]){
                    props.selectTexture(index)
                  }
                }}
                style={{
                  left: `${(index)*props.sideLength}px`,
                  height: `${props.sideLength}px`,
                  width: `${props.sideLength}px`,
                  minWidth: `${props.sideLength}px`,
                  justifyContent: 'center',
                  alignItems: 'center',
                  //transform: `scale(${1 - Math.pow(Math.abs(centerIndex - index),1.3)*.1})`,
                  //opacity: `${1 - Math.pow(Math.abs(centerIndex - index),1.5)*.25}`,
                  overflow: 'hidden'
                }}
              >
              {
                props.textures[index] ? 
                <img 
                  alt={props.textures[index].image} 
                  style={{height: '90%', width: '90%', borderRadius:`${props.sideLength/2}px`, border: index === props.currentTexture ? '4px inset rgba(255,255,255,0.5)' : 'none'}} 
                  src={textureFolder + props.textures[index].thumb} 
                />
                :
                null
              }

              </Container>
        )}
      </Container>
      <Container
          style={{
            background: 'radial-gradient(ellipse at center, rgba(0,0,0,0) 0%,rgba(0,0,0,0.8) 40%,rgba(0,0,0,1)',
            pointerEvents: 'none',
            height: '200%'
          }}>
          
          </Container>
    </Container>
  )
}

const TexturePicker = (props) => {
  const sideLength = Math.min(Math.min(props.width, props.height)/textures.length, 50)

  //console.log(props)

  const spacing = props.height / textures.length
  const paddingTop = spacing/3


  const textureThumbnails = props.textures.map((texture, index) => 
    <TextureThumbnail spacing={spacing} sideLength={sideLength} key={texture.image} selectHoveredTexture={props.selectHoveredTexture} selectTexture={props.selectTexture} selected={props.currentTexture === index} texture={texture} index={index}/>
  )

  return(
    <Container 
      style={{
        height:'100%', 
        width: sideLength*3,
        bottom : isMobile ? '0px' : null,
        top: paddingTop,
        left: isMobile ? null : '0px', 
        flexFlow: isMobile ? 'row' : 'column'
      }}>
      {textureThumbnails}
      <Container style={{pointerEvents: 'none'}}>
        <svg style={{width: '100%', height: '100%'}}>
          <rect  y={props.currentTexture*spacing} width={sideLength*3} height={sideLength} style={{fill: 'none', strokeWidth: '4', stroke: 'rgb(255,255,255)'}}/>
          {
          (props.hoveredTexture !== null) ? 
          <rect  y={props.hoveredTexture*spacing} width={sideLength*3} height={sideLength} style={{fill: 'none', strokeWidth: '4', stroke: 'rgb(220,220,220)'}}/> : null
          }
        </svg>
      </Container>
    </Container>
  )
}

const ToolTip = (props) => {

  return(
    <Container
    onClick={props.hide}
    style={{
      position: 'fixed',
      top: '0',
      bottom: '0',
      left: '0',
      right: '0',
      transition: 'opacity 250ms cubic-bezier(0.215, 0.61, 0.355, 1)',
      opacity: (props.message || props.active) ? 1 : 0,
      background: 'rgba(0,0,0,0.85)',
      justifyContent: 'center',
      pointerEvents: props.active && isMobile ? 'auto' : 'none'
    }}
  >
    <Container
      style={{
        width: isMobile ? '100%' : '50%',
        color: 'white',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '32px',
        flexFlow: 'column',
        fontFamily: 'Staatliches',
        textAlign: 'center'
      }}
    >
    <Container
      style={{
        position: 'relative',
        height: '40px',
        justifyContent: 'center'
      }}
      >
        <span style={{lineHeight: '50px', fontSize: isMobile ? '32px' : '52px',}}>{props.message}</span>
    </Container>
    </Container>
  </Container>
  )

}

const Vis = (props) => {

  const mount = useRef(null)
  const [isAnimating, setAnimating] = useState(true)
  const [currentTexture, setCurrentTexture] = useState(Math.floor(textures.length/2))
  const [hoveredTexture, setHoveredTexture] = useState(null)
  const [boardTextures, setBoardTextures] = useState([])
  const [readyToRender, setReadyToRender] = useState(false)
  const [brandLink, setBrandLink] = useState(null)

  const controls = useRef(null)

  textures.forEach(texture => {
    boardTextures.push(null)
  })
  var textureLoader = new THREE.TextureLoader();

  useEffect(() => {
    let width = mount.current.clientWidth
    let height = mount.current.clientHeight
    let frameId

    let boardObj;
    let board

    let currentPosX = 0;
    let currentPosY = 0;
    let prevPosX = 0;
    let prevPosY = 0;

    textures.forEach((texture, i) => {
      textureLoader.load(
        textureFolder + texture.image,
        function ( texture ) {
          boardTextures.splice(i, 1, texture)
          setBoardTextures(boardTextures)
          if(i === textures.length - 1) {
            //controls.current.addSelector(textures.length)
            setReadyToRender(true)
          } 
        },
        undefined,
        function ( err ) {
          console.error( 'An error happened.' );
        }
      );
    })

    const	camera = new THREE.PerspectiveCamera( 40, window.innerWidth / window.innerHeight, 1, 1000 );
    const origin = new THREE.Vector3(0, 0, 0)
    const rotationAxis = new THREE.Vector3(0, 1, 0)
    camera.position.set( 0, 50, 190 );
    camera.lookAt(origin)
    const	scene = new THREE.Scene();

    const renderer = new THREE.WebGLRenderer()

    var loader = new THREE.OBJLoader();

		loader.load( './js/three/board3.obj', function ( object ) {
      boardObj = object
			//mesh = new THREE.Mesh( geometry, material );
			boardObj.position.set( 0, 0, -60 );
      boardObj.rotation.set( -Math.PI/2, 0, 0 );
      prevPosX = boardObj.rotation.z
      prevPosY = boardObj.rotation.x
      currentPosX = prevPosX;
      currentPosY = prevPosY;
			boardObj.scale.set( 3, 3, 3 );
			boardObj.castShadow = true;
      boardObj.receiveShadow = true;
      board = boardObj.children[0]
      board.material.map = boardTextures[currentTexture]
      board.material.precision = 'highp'
			scene.add( boardObj );
		} );

         // Load the background texture
        
         var texture = textureLoader.load(isMobile ? '/images/brick_bg_mobile.jpg' : '/images/brick_bg.jpg' );
         texture.wrapS = THREE.RepeatWrapping
         texture.wrapT = THREE.RepeatWrapping
         var backgroundMesh = new THREE.Mesh(
             new THREE.PlaneGeometry(2, 2, 0),
             new THREE.MeshBasicMaterial({
                 map: texture
             }));
 
         backgroundMesh .material.depthTest = false;
         backgroundMesh .material.depthWrite = false;
 
         // Create your background scene
         var backgroundScene = new THREE.Scene();
         var backgroundCamera = new THREE.Camera();
         backgroundScene .add(backgroundCamera );
         backgroundScene .add(backgroundMesh );

    //camera.position.z = 4
    //scene.add(cube)
    renderer.setClearColor('#000000')
    renderer.setSize(width, height)

    let cameraControls = new OrbitControls(camera, renderer.domElement)
    cameraControls.rotateSpeed = isMobile ? 0.2 : 0.1;
    //cameraControls.autoRotateSpeed = 15
    cameraControls.enableZoom = false
    cameraControls.enablePan = false
    cameraControls.enableDamping = true
    cameraControls.dampingFactor = 0.08
    //cameraControls.autoRotate = true
    cameraControls.maxDistance = 250
    cameraControls.target = new THREE.Vector3(0, 0, -60)
    cameraControls.minDistance = 250
  
    cameraControls.maxPolarAngle = Math.PI/2 + 1
    cameraControls.minPolarAngle = Math.PI/2 - 1


  let lights = [
    { x: 60, y: 60, z: 30, },
    { x: -80, y: 60, z: 30 },
    { x: 0, y: -10, z: 10 },
  ] 

  lights.forEach(light => {
    let spotlight = new THREE.SpotLight(0xffffff)
    spotlight.position.set(light.x, light.y, light.z)
    spotlight.penumbra = 19
    spotlight.castShadow = true
    spotlight.shadow.mapSize.width = 1024
    spotlight.shadow.mapSize.height = 1024
    
    spotlight.decay = 2
    spotlight.distance = 500
    spotlight.power = 1
  
    spotlight.shadow.camera.near = 6000
    spotlight.shadow.camera.far = 100000
    spotlight.shadow.camera.fov = 40
    scene.add(spotlight)
    //console.log(scene)
  })

  renderer.setPixelRatio( window.devicePixelRatio );
  renderer.setSize( props.width, props.height );
  renderer.gammaInput = true;
  renderer.gammaOutput = true;
  renderer.shadowMap.enabled = true;


    const renderScene = () => {
      renderer.autoClear = false
      cameraControls.update()
      renderer.clear()
      renderer.render(backgroundScene , backgroundCamera );
      renderer.render(scene, camera)
    }

    const handleResize = () => {
      width = props.width
      height = props.height
      renderer.setSize(width, height)
      camera.aspect = width / height
      camera.updateProjectionMatrix()
      renderScene()
    }

    const animate = () => {
      //mesh.rotation.x += 0.0
      if(readyToRender) renderScene()
      frameId = window.requestAnimationFrame(animate)
    }

    const start = () => {
      if (!frameId) {
        frameId = requestAnimationFrame(animate)
      }
    }

    const stop = () => {
      cancelAnimationFrame(frameId)
      frameId = null
    }

    const changeTexture = (texture) => {
      if(board){
        board.material.map = boardTextures[texture]
          board.material.needsUpdate = true
          board.material.map.needsUpdate = true
      }
      renderScene()
    }

    
    const userRotate = (dataDrag) => {
      //console.log(dataDrag)
      //console.log(dataDrag)
      if(dataDrag.isMouseDown){
          //if move pos is 0
          //start of drag
          if(!dataDrag.isMoving){
            //console.log('reset x at: ' + currentPosX)
            prevPosX = currentPosX
            prevPosY = currentPosY
            //setPrevRotationY(boardObj.rotation.x)

            //setCurrentMovePosX(0)
            //setCurrentMovePosY(0)
          }
          else{
            
            const movePosY = prevPosY + dataDrag.moveDeltaY/200.0
            const movePosX = prevPosX + dataDrag.moveDeltaX/150.0

            currentPosX = movePosX
            currentPosY = movePosY
            boardObj.rotation.z = movePosX
            boardObj.rotation.x = movePosY
            //console.log(movePosX)
            //console.log(movePosY)
            //boardObj.rotation.z += dataDrag.moveDeltaX/1000.0
            //boardObj.rotation.x += dataDrag.moveDeltaY/1000.0
            //stop()
            renderScene()
          }

      }
    }
    

    mount.current.appendChild(renderer.domElement)
    window.addEventListener('resize', handleResize)
    if(readyToRender){
      start()
    }


    controls.current = { start, stop, changeTexture, userRotate, renderScene }
    
    return () => {
      stop()
      window.removeEventListener('resize', handleResize)
      mount.current.removeChild(renderer.domElement)

      //scene.remove(mesh)
      //material.dispose()
    }
  }, [readyToRender])

  // useEffect(() => {
  //   console.log(currentTexture)
  //   controls.current.changeTexture(currentTexture)
  // }, [currentTexture, hoveredTexture])
  const [sliderPos, setSliderPos] = useState(0)
  const [prevSliderPos, setPrevSliderPos] = useState(sliderPos)
  const [numDragEvents, setNumDragEvents] = useState(0)

  useEffect(() => {
    let sliceSize = props.width/textures.length
    let newIndex = Math.floor(sliderPos / sliceSize)
    //console.log(sliderPos)
    //if(textures[newIndex]) handleTextureChange(newIndex)

  }, [sliderPos])

  
  useEffect(() => {
    //let indexChange
    //console.log(props.dataDrag)
    if(isMobile){

    }
    if(isMobile){
      if(props.dataDrag.isMouseDown){
        if(props.dataDrag.mouseDownPositionY < (props.height - 100)){
          //console.log(props.dataDrag.mouseDownPositionY)
          //controls.current.userRotate(props.dataDrag)
        }
        else{
          if (!props.dataDrag.isMoving) {
            setPrevSliderPos(sliderPos)
          }
          else{
            let newPos = prevSliderPos + props.dataDrag.moveDeltaX
            setSliderPos(Math.min(Math.max(newPos, -25), textures.length*sideLength - props.width + 25))
          }
        }
      }
      else{
        setNumDragEvents(numDragEvents + 1)
      }

    }

    else{
      //controls.current.userRotate(props.dataDrag)
    }
  }, [props.dataDrag, prevSliderPos])

  useEffect(() => {
    setPrevSliderPos(sliderPos)
  }, [numDragEvents])


  const handleTextureChange = (texture) => {
    controls.current.changeTexture(texture)
    setCurrentTexture(texture)
  }


  useEffect(() => {

    if (isAnimating) {
      controls.current.start()
    } else {
      controls.current.stop()
    }
  }, [isAnimating])

  const sideLength = 100
  let brandName = textures[currentTexture].brandName
  let brand
  if(brandName){
    brand = brands[textures[currentTexture].brandName]
  }

  return (
    <Container style={{height:props.height, width: props.width}}>

      <div
          style={{  position: 'absolute', height: '100%', width: '100%'}}
          ref={mount}
      />    
            <Container style={{pointerEvents: 'none'}}>
        {/*<img src='/images/cautiontape.png' style={{width: '100%', objectFit: 'cover'}} alt='caution'/>*/}
        {
        isMobile ?
      <Container onClick={() => setBrandLink(brandlink => brandlink ? null : 'Tucson Skateboarding')} 
        style={{
          top: '25px', 
          height: '50px', 
          alignItems: 'center', 
          justifyContent: 'center', 
          pointerEvents: 'auto',
          zIndex: brandLink === 'Tucson Skateboarding' ? '2' : '0',
        }}
        
        >
        <img alt='blxlogo' style={{height: '50px', objectFit: 'contain'}} src='/images/blx_logo.gif'/>
      </Container> 
      :
      <Container
        onMouseEnter={() => setBrandLink('Tucson Skateboarding')}
        onMouseLeave={() => setBrandLink(null)}
        style={{top: '40px', right: '40px', width: '100px', height: '60px', alignItems: 'center', justifyContent: 'center', pointerEvents: 'auto', zIndex: '2',}}>
        <img alt='blxlogo' style={{height: '60px', objectFit: 'contain'}} src='/images/blx_logo.gif'/>
      </Container> 
      }
      </Container>
      {
        isMobile ?
        <MobileTexturePicker sideLength={sideLength} sliderPos={sliderPos} height={props.height} width={props.width} currentTexture={currentTexture} hoveredTexture={hoveredTexture} selectTexture={handleTextureChange} selectHoveredTexture={setHoveredTexture} textures={textures}/>
        :
        <TexturePicker height={props.height} width={props.width} currentTexture={currentTexture} hoveredTexture={hoveredTexture} selectTexture={handleTextureChange} selectHoveredTexture={setHoveredTexture} textures={textures}/>
      }
      {
        (brandName && brand) &&
        <Container
          onMouseEnter={() => setBrandLink(brand.url)}
          onMouseLeave={() => setBrandLink(null)}
          style={{
            zIndex: brandLink === 'Tucson Skateboarding' ? '0' : '2',
            height: isMobile ? '15vh' : '20vw',
            width: isMobile ? '15vh' : '20vw',
            right: isMobile ? '2vw' : '5vh',
            bottom: isMobile ? '20vh' : '5vh',
            alignItems: 'center'
          }}
        >

          <BrandLogo brand={brands[textures[currentTexture].brandName]} />
        </Container>
      }
      <ToolTip hide={() => setBrandLink(null)} message={brandLink} active={brandLink}/>
    </Container>
  )
}

var ClickDragVis = clickdrag(Vis, {touch:true})

export default ClickDragVis
