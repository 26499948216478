import React from 'react'

export default function(props){

  return(
    <div
      onClick={props.onClick}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
      style={{width:'100%', height: '100%', position: 'absolute', display: 'flex', ...props.style}}
    >
      {props.children}
    </div>
  )
}
