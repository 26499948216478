import React from 'react';
import Vis from './Components/Vis'
import {isMobile} from 'react-device-detect'
import Container from './Components/Container'
import './App.css';


function App() {
  const width = isNaN(window.innerWidth) ? window.clientWidth : window.innerWidth;
  const height = isNaN(window.innerHeight) ? window.clientHeight : window.innerHeight;
  return (
    <Container style={{position:'fixed', top: '0px', bottom: '0px', right: '0px', left: '0px'}}>
      <Container>

        <Vis width={width} height={height}/>
      </Container>
    </Container>
  );
}

export default App;
